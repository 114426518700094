<template>
  <div class="calendar-form">
    <Loader v-if="isLoading" />
    <div class="calendar-form__title" v-if="slideInTitle">
      {{ slideInTitle }}
    </div>
    <div class="calendar-form__list">
      <form>
        <div class="labels">
          <span class="labels__title">Scheduling</span>
          <Scheduling
            class="scheduling"
            :date="currentDate"
            :disabledTime="-24"
            @changeDate="changeDateHandler"
            @clearDate="changeDateHandler('')"
          />
        </div>
      </form>
      <div class="controls">
        <button
          @click="$emit('close')"
          class="btn violet cancel controls__item"
        >
          Cancel
        </button>
        <button
          @click="saveForm()"
          class="btn violet controls__item"
          :disabled="currentDate === ''"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Scheduling from "@/components/dashboard/widgets/creationWidget/components/Scheduling";
import Loader from "@/components/global/Loader";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CalendarStoriesForm",
  components: {
    Scheduling,
    Loader,
  },
  data: () => ({
    currentDate: "",
    isLoading: false,
  }),
  props: {
    eventData: {
      type: Object,
      default: () => {},
    },
    slideInTitle: {
      type: String,
      default: "",
    },
    slideInType: {
      type: String,
      default: "",
    },
  },
  created() {
    if (this.slideInType === "createStories") {
      this.setPostDate();
    }
  },
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
  },
  methods: {
    ...mapActions("calendar", ["savePost"]),
    changeDateHandler(date) {
      this.currentDate = date;
    },
    setPostDate() {
      const eventDate = this.$dayjs(this.eventData.date).format("YYYY-MM-DD");
      const today = this.$dayjs(new Date());
      if (this.$dayjs(eventDate).diff(today, "day") < 0) {
        this.currentDate = `${this.$dayjs(today).format(
          "YYYY-MM-DD"
        )}_12-00-00`;
      } else {
        this.currentDate = `${this.$dayjs(this.eventData.date).format(
          "YYYY-MM-DD"
        )}_12-00-00`;
      }
    },
    async saveForm() {
      this.isLoading = true;
      const params = {
        publishAtStr: this.currentDate,
        customerId: this.getCurrentCustomer.id,
        type: "story",
      };
      await this.savePost(params);
      this.$emit("close");
      this.$emit("updatePost", this.currentDate);
      this.isLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.calendar-form {
  width: 100%;
  &__title {
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    .labels {
      &__title {
        width: 100%;
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        color: #1f2325;
        margin-bottom: 4px;
      }
      .form-control {
        ::v-deep {
          .v-text-field--outlined > .v-input__control > .v-input__slot {
            min-height: 36px;
            max-height: 36px;
          }
          .v-text-field__details {
            display: none !important;
          }
          .v-text-field--enclosed .v-input__append-inner {
            margin-top: 7px;
          }
        }
      }
      .scheduling {
        margin: 0 -5px;
        ::v-deep {
          .mx-datepicker {
            width: calc(50% - 5px);
            margin: 0 5px;
          }
        }
      }
    }
  }
  .controls {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__item {
      min-width: 150px;
      &[disabled] {
        background: #ccc;
      }
    }
  }
}
</style>
