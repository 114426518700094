<template>
  <div class="img" v-if="imgId">
    <img :src="imgSrc" alt="" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import MediaFile from "@/models/MediaFile";

export default {
  name: "CalendarPrevImage",
  props: {
    imgId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    imgSrc: "",
  }),
  async created() {
    if (this.imgId) {
      this.imgSrc = await this.getImageForEvent();
    }
  },
  methods: {
    ...mapActions("mediaLibraryWidget", ["getMediaInfo"]),

    async getImageForEvent() {
      let { mediaUrl } = await this.getMediaInfo(this.imgId);
      return mediaUrl;
    },
  },
};
</script>

<style scoped lang="scss">
.img {
  width: 100%;
  text-align: center;
  img {
    width: auto;
    max-height: 100px;
  }
}
</style>
