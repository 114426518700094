<template>
  <div class="preview">
    <div class="preview__title" v-if="eventData.type === 'story'">
      Preview story
    </div>
    <div v-else class="preview__title">Preview post</div>
    <div v-if="eventData.postStatus === 'done'">
      <div class="slider-wrapper">
        <v-carousel
          v-if="eventData.post.media.length"
          height="300"
          :continuous="false"
          hide-delimiter-background
          hide-delimiters
        >
          <v-carousel-item
            v-for="(item, i) in eventData.post.media"
            :key="i"
            :src="item.src"
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
            <div class="preview__img">
              <img
                v-if="
                  isImage(
                    item.editedMediaUrlReduced
                      ? item.editedMediaUrlReduced
                      : item.mediaUrl
                  )
                "
                :src="
                  item.editedMediaUrlReduced
                    ? item.editedMediaUrlReduced
                    : item.mediaUrl
                "
                alt=""
              />
              <video
                v-else
                controls
                :src="item.editedMediaUrl ? item.editedMediaUrl : item.mediaUrl"
              />
            </div>
          </v-carousel-item>
        </v-carousel>
      </div>
      <div class="preview__data-list" v-if="eventData.type !== 'story'">
        <div class="item">
          <div class="item__name">Subtheme:</div>
          <div class="item__data">{{ eventData.post.contentSubtheme }}</div>
        </div>
        <div class="item">
          <div class="item__name">Subject:</div>
          <div class="item__data">{{ eventData.post.contentSubject }}</div>
        </div>
        <div class="item">
          <div class="item__name">Content Caption:</div>
          <div class="item__data">
            <div class="item__data-text caption">
              {{ eventData.post.text }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="item__name">Content tags:</div>
          <div class="item__data" v-if="eventData.post.tags.length">
            <span
              class="item__data-text"
              v-for="(tag, index) in eventData.post.tags"
              :key="index"
            >
              {{ tag }}
            </span>
          </div>
        </div>
        <div class="item">
          <div class="item__name">Preferred site:</div>
          <div class="item__data">
            <a
              class="item__data-link"
              target="_blank"
              :href="eventData.preferred_site"
            >
              {{ eventData.preferred_site }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="preview__img" v-if="mediaFile.mediaUrl">
        <img :src="mediaFile.mediaUrl" alt="" />
      </div>
      <div class="preview__data-list">
        <div class="item">
          <div class="item__name">Theme:</div>
          <div class="item__data">{{ eventData.contentTheme }}</div>
        </div>
        <div class="item">
          <div class="item__name">Subtheme:</div>
          <div class="item__data">{{ eventData.contentSubtheme }}</div>
        </div>
        <div class="item">
          <div class="item__name">Subject:</div>
          <div class="item__data">{{ eventData.contentSubject }}</div>
        </div>
        <div class="item">
          <div class="item__name">Content Caption:</div>
          <div class="item__data">
            <v-carousel
              v-if="contentCaptionList.length"
              class="content-caption__slider"
              height="200"
              :continuous="false"
              hide-delimiter-background
              hide-delimiters
              @change="changeSliderHandler"
            >
              <v-carousel-item
                v-for="item in contentCaptionList"
                :key="item.id"
              >
                <div class="d-flex justify-end pr-4">
                  <v-icon
                    v-if="!item.isClickedToEditIcon"
                    @click="item.isClickedToEditIcon = true"
                    >mdi-pencil-circle-outline</v-icon
                  >
                </div>
                <div v-if="item.isClickedToEditIcon" class="edit-caption-block">
                  <MyRichTextEditor
                    :value="item.fakeCaptionHtml || item.fakeText"
                    :is-used-html="!!item.fakeCaptionHtml"
                    is-show-emoji
                    is-show-tooltip
                    :link="item.sourceLink"
                    :tooltip="item.metaCaptionHTML"
                    @editorChanged="editorChangedHandler($event, item)"
                  />
                  <div class="edit-caption-block__buttons">
                    <v-btn
                      x-small
                      class="red"
                      @click="cancelSaveCaptionHandler(item)"
                      >Cancel</v-btn
                    >
                    <v-btn
                      x-small
                      class="green"
                      @click="approveSaveCaptionHandler(item)"
                      >Save</v-btn
                    >
                  </div>
                </div>

                <div v-else class="item__data-text caption">
                  {{ item.text }}
                </div>
              </v-carousel-item>
            </v-carousel>
          </div>
        </div>
        <div class="item">
          <div class="item__name">Content tags:</div>
          <div class="item__data">
            <div class="d-flex justify-end pr-4">
              <v-icon
                v-if="!eventData.isClickedToEditHashtagsIcon"
                @click="
                  $emit('updateEventData', {
                    isClickedToEditHashtagsIcon: true,
                  })
                "
                >mdi-pencil-circle-outline</v-icon
              >
            </div>
            <div v-if="!eventData.isClickedToEditHashtagsIcon">
              <span
                class="item__data-text"
                v-for="(tag, index) in eventData.contentHashtags"
                :key="index"
              >
                {{ tag }}
              </span>
            </div>
            <template v-else>
              <Hashtags
                :hashtagsList="eventData.contentHashtags"
                @changeHashtagsList="hashtagsChangedHandler"
              />
              <div class="edit-caption-block__buttons">
                <v-btn
                  x-small
                  class="red"
                  @click="
                    $emit('updateEventData', {
                      isClickedToEditHashtagsIcon: false,
                      contentHashtags: eventData.contentHashtagsBeforeEdited,
                      contentHashtagsBeforeEdited: JSON.parse(
                        JSON.stringify(eventData.contentHashtagsBeforeEdited)
                      ),
                    })
                  "
                  >Cancel</v-btn
                >
                <v-btn x-small class="green" @click="saveHashtagsHandler"
                  >Save</v-btn
                >
              </div>
            </template>
          </div>
        </div>
        <div class="item">
          <div class="item__name">Preferred site:</div>
          <div class="item__data">
            <a
              class="item__data-link"
              target="_blank"
              :href="eventData.preferred_site"
            >
              {{ eventData.preferred_site }}
            </a>
          </div>
        </div>
        <div class="d-flex justify-center">
          <v-btn
            class="btn violet light"
            :loading="isLoadingApproveBtn"
            @click="$emit('clickedApprove')"
          >
            Approve
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import MediaFile from "@/models/MediaFile";
import helper from "@/mixins/helper";
import MyRichTextEditor from "@/components/global/MyRichTextEditor";
import Hashtags from "@/components/global/Hashtags";

export default {
  name: "CalendarPreview",
  components: { MyRichTextEditor, Hashtags },
  data: () => ({
    mediaFile: new MediaFile(),
    contentCaptionList: [],
  }),
  mixins: [helper],
  props: {
    eventData: {
      type: Object,
      default: () => {},
    },
    isLoadingApproveBtn: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    await this.getMediaFile();
    await this.getContentCaptionList();
  },
  computed: {
    contentCaptionIsShow() {
      let result = true;
      this.eventData.contentCaptionIds.forEach((item) => {
        if (item === "blank-caption-id") {
          result = false;
        }
      });
      return result;
    },
  },
  methods: {
    ...mapActions("mediaLibraryWidget", ["getMediaInfo"]),
    ...mapActions("calendar", ["fetchContentCaption"]),
    ...mapActions("dashboard", ["updatePostCaption"]),
    async getMediaFile() {
      if (this.eventData.contentMediaIds.length) {
        this.mediaFile = await this.getMediaInfo(
          this.eventData.contentMediaIds[0]
        );
      }
    },

    async getContentCaptionList() {
      if (
        this.contentCaptionIsShow &&
        this.eventData.contentCaptionIds &&
        this.eventData.contentCaptionIds.length
      ) {
        let results = [];
        await Promise.all(
          this.eventData.contentCaptionIds.map(async (captionId, index) => {
            results[index] = await this.fetchContentCaption(captionId);
          })
        );
        this.contentCaptionList = results;
      }
    },
    editorChangedHandler({ html, text }, captionItem) {
      captionItem.captionHTML = html || null;
      captionItem.text = text || null;
    },
    async approveSaveCaptionHandler(captionItem) {
      try {
        await this.updatePostCaption({
          textId: captionItem.id,
          text: captionItem.text,
          captionHTML: "",
        });

        captionItem.fakeCaptionHtml = "";
        captionItem.captionHTML = "";
        captionItem.fakeText = captionItem.text;
        captionItem.captionHtmlBeforeEdit = captionItem.captionHTML;
        captionItem.textBeforeEdit = captionItem.text;
        captionItem.isClickedToEditIcon = false;
      } catch (e) {
        throw e;
      }
    },
    cancelSaveCaptionHandler(captionItem) {
      captionItem.isClickedToEditIcon = false;
      captionItem.captionHTML = captionItem.captionHtmlBeforeEdit;
      captionItem.text = captionItem.textBeforeEdit;
    },
    changeSliderHandler(index) {
      this.$emit("changeCaptionOnSlider", this.contentCaptionList[index]);
      this.contentCaptionList.forEach((item) => {
        this.cancelSaveCaptionHandler(item);
      });
    },
    hashtagsChangedHandler(hashtags) {
      this.$emit("updateEventData", {
        contentHashtags: hashtags,
        contentHashtagsBeforeEdited: JSON.parse(
          JSON.stringify(this.eventData.contentHashtagsBeforeEdited)
        ),
      });
    },
    saveHashtagsHandler() {
      this.$emit("saveBtnHandler", {
        contentHashtags: this.eventData.contentHashtags,
        contentHashtagsBeforeEdited: JSON.parse(
          JSON.stringify(this.eventData.contentHashtags)
        ),
      });

      this.eventData.isClickedToEditHashtagsIcon = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.preview {
  width: 100%;
  &__title {
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  &__img {
    width: 100%;
    text-align: center;
    height: 300px;
    img {
      max-height: 300px;
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__data-list {
    display: flex;
    flex-direction: column;
    .item {
      display: inline-flex;
      flex-direction: column;
      margin-bottom: 15px;
      &__name {
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #1f2325;
        margin-bottom: 4px;
      }
      &__data {
        width: 100%;
        font-size: 14px;
        line-height: 140%;
        color: #1f2325;
        &-text {
          &.caption {
            white-space: pre-wrap;
            width: 100%;
            font-size: 14px !important;
            font-weight: 400 !important;
            line-height: 140%;
            color: #1f2325;
            font-family: Inter, sans-serif !important;
          }
        }
      }
    }
  }
  .content-caption__slider {
    position: relative;
    overflow: inherit !important;
    margin-top: 5px;
    height: auto !important;
    ::v-deep {
      .v-image {
        height: auto !important;
      }
      .v-btn--icon.v-size--default {
        height: 26px;
        width: 26px;
        i {
          font-size: 25px !important;
        }
      }
      .v-window__prev {
        position: absolute;
        top: -32px;
        right: 50px;
        left: auto;
        z-index: 10000;
      }
      .v-window__next {
        top: -32px;
        z-index: 10000;
      }
      .v-responsive {
        overflow: inherit !important;
      }
    }
  }
  .slider-wrapper {
    height: 300px;
    margin-bottom: 15px;
    .v-carousel {
      height: 300px !important;
      overflow: hidden;
      ::v-deep {
        .v-image {
          height: 300px !important;
        }
      }
    }
  }
  .edit-caption-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: $color-white;
    border-radius: 10px;
    padding: 5px;
    &__buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 15px;
      .v-btn {
        border-radius: 18px !important;
      }
      .v-btn--disabled {
        border: unset !important;
      }
      .v-btn--is-elevated {
        box-shadow: unset !important;
      }
      .green {
        color: $color-white;
        margin-left: 10px;
        background: $color-white;
        border: 1px solid $color-green;
      }
      .red {
        color: $color-white;
        background: $color-white;
        border: 1px solid $color-red;
      }
    }
  }
  .btn {
    height: 36px;
    width: 100%;
    max-width: 200px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: $color-white;
    border-radius: 16px;
    background: $color-violet;
    transition: all 0.3s ease;
    &:hover {
      background: darken($color-violet, 10);
    }
    &.light {
      max-width: 120px;
      margin-left: 15px;
    }
  }
}
</style>
