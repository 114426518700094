<template>
  <v-combobox
    :delimiters="[',', ' ', ';', '#']"
    @change="hashtagsInput($event)"
    v-model="hashtagsListComputed"
    chips
    hide-details
    class="tags_container"
    multiple
    outlined
  >
    <template v-slot:selection="{ attrs, item, index, select, selected }">
      <draggable
        :id="index"
        :list="hashtagsListComputed"
        v-bind="dragOptionsChips"
        :move="move"
        @change="change($event)"
        handle=".v-chip__content strong"
      >
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          close
          color="#7e5689"
          small
          draggable
          class="txt-color"
          @mousedown.stop
          @click.stop
          @click="select"
          @click:close="deleteTagHandler(item)"
        >
          <strong>{{ item }}</strong>
        </v-chip>
      </draggable>
    </template>
  </v-combobox>
</template>

<script>
import H from "@/utils/helper";

export default {
  name: "Hashtags",
  props: {
    hashtagsList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    dragOptionsChips() {
      return {
        animation: 200,
        group: "group",
        disabled: false,
        ghostClass: "ghost",
        sort: true,
      };
    },
    hashtagsListComputed: {
      get() {
        return JSON.parse(JSON.stringify(this.hashtagsList));
      },
      set(newHashtags) {
        this.$emit("changeHashtagsList", newHashtags);
      },
    },
  },
  methods: {
    hashtagsInput(val) {
      if (!val && !val.length) return;

      if (this.hashtagsListComputed.length < 30) {
        this.hashtagsListComputed = H.hashtagsCompose(val);
      } else {
        this.hashtagsListComputed = this.hashtagsListComputed.filter(
          (item, index) => index < 30
        );
      }
    },
    move(value) {
      this.dragged = {
        from: parseInt(value.from.id),
        to: parseInt(value.to.id),
        newIndex: value.draggedContext.futureIndex,
      };
    },
    change(value) {
      if (value.removed) {
        this.hashtagsListComputed.splice(
          this.dragged.to + this.dragged.newIndex,
          0,
          this.hashtagsListComputed[this.dragged.from]
        );
        if (this.dragged.from < this.dragged.to)
          this.hashtagsListComputed.splice(this.dragged.from, 1);
        else this.hashtagsListComputed.splice(this.dragged.from + 1, 1);

        this.$emit("changeHashtagsList", this.hashtagsListComputed);
      }
    },
    deleteTagHandler(tag) {
      this.hashtagsListComputed = this.hashtagsListComputed.filter(
        (val) => val !== tag
      );
    },
  },
};
</script>

<style scoped lang="scss">
.tags_container {
  .v-input__append-inner {
    display: none !important;
  }
}
</style>
