<template>
  <div class="wrapper">
    <Loader v-if="isLoading" />
    <div class="calendar-client">
      <div class="calendar-client__img">
        <img
          v-if="getCurrentCustomer.picUrl"
          :src="getCurrentCustomer.picUrl"
          alt=""
        />
        <img v-else src="@/assets/default-profile-picture.png" alt="" />
      </div>
      <div class="calendar-client__name">{{ getCurrentCustomer.name }}</div>
    </div>
    <div class="post-number">
      <div class="post-number__wrapper">
        <span class="name">Weekly Posts:</span>
        <span class="data" v-if="getCurrentCustomer.post_per_week">{{
          getCurrentCustomer.post_per_week
        }}</span>
      </div>
      <div class="post-number__wrapper">
        <span class="name">Weekly Stories:</span>
        <span class="data" v-if="getCurrentCustomer.story_per_week">{{
          getCurrentCustomer.story_per_week
        }}</span>
      </div>
    </div>
    <FullCalendar ref="fullCalendar" :options="calendarOptions">
      <template v-slot:eventContent="event">
        <div
          class="item"
          :class="{
            done: event.event.extendedProps.postStatus === 'done',
            disabled: isDisabledItem(event.event.start),
            auto: event.event.extendedProps.generation === 'auto',
            story: event.event.extendedProps.type === 'story',
          }"
        >
          <div
            class="item__wrapper"
            v-if="event.event.extendedProps.postStatus === 'done'"
          >
            <div
              class="item__header"
              v-if="event.event.extendedProps.type === 'story'"
            >
              <div class="item__header-title">Story</div>
            </div>
            <div class="item__time">
              Publish time:
              {{
                event.event.extendedProps.post.scheduledForStr
                  .split("_")[1]
                  .substr(0, 5)
              }}
            </div>
            <div
              class="item__title"
              v-if="event.event.extendedProps.post.contentSubject"
            >
              {{ event.event.extendedProps.post.contentSubject }}
            </div>
            <div
              class="item__img"
              v-if="event.event.extendedProps.post.media.length"
            >
              <img
                v-if="
                  isImage(
                    event.event.extendedProps.post.media[0]
                      .editedMediaUrlReduced
                      ? event.event.extendedProps.post.media[0]
                          .editedMediaUrlReduced
                      : event.event.extendedProps.post.media[0].mediaUrl
                  )
                "
                :src="
                  event.event.extendedProps.post.media[0].editedMediaUrlReduced
                    ? event.event.extendedProps.post.media[0]
                        .editedMediaUrlReduced
                    : event.event.extendedProps.post.media[0].mediaUrl
                "
                alt=""
              />
              <video
                v-else
                controls
                :src="
                  event.event.extendedProps.post.media[0].editedMediaUrl
                    ? event.event.extendedProps.post.media[0].editedMediaUrl
                    : event.event.extendedProps.post.media[0].mediaUrl
                "
              />
            </div>
            <a
              class="item__link"
              target="_blank"
              v-if="event.event.extendedProps.preferred_site"
              :href="event.event.extendedProps.preferred_site"
              >{{ event.event.extendedProps.preferred_site }}</a
            >
            <div class="item__footer">
              <span class="item__footer-btn" @click="showPreview(event)">
                <i class="icon-eye_preview"></i>
              </span>
              <span
                class="item__footer-btn right"
                @click="
                  goToEditPageHandler(event.event.extendedProps, event.event.id)
                "
                v-if="
                  !isDisabledItem(event.event.start) &&
                  event.event.extendedProps.type !== 'story'
                "
              >
                <i class="icon-edit"></i>
              </span>
              <span
                class="item__footer-btn right"
                @click="
                  goToEditPageStoriesHandler(
                    event.event.extendedProps,
                    event.event.id
                  )
                "
                v-else-if="!isDisabledItem(event.event.start)"
              >
                <i class="icon-edit"></i>
              </span>
            </div>
          </div>
          <div class="item__wrapper" v-else>
            <div
              class="item__header"
              v-if="event.event.extendedProps.type === 'story'"
            >
              <div class="item__header-title">Story</div>
              {{ event.event.extendedProps.post }}
            </div>
            <div class="item__time">
              Publish time:
              {{
                event.event.extendedProps.publishAtStr
                  .split("_")[1]
                  .substr(0, 5)
              }}
            </div>
            <div
              class="item__title"
              v-if="event.event.extendedProps.contentSubtheme"
            >
              {{ event.event.extendedProps.contentSubtheme }}
            </div>
            <CalendarPrevImage
              class="img"
              :imgId="event.event.extendedProps.contentMediaIds[0]"
            />
            <a
              class="item__link"
              target="_blank"
              v-if="event.event.extendedProps.preferred_site"
              :href="event.event.extendedProps.preferred_site"
              >{{ event.event.extendedProps.preferred_site }}</a
            >
            <div class="item__footer" v-if="!isDisabledItem(event.event.start)">
              <span
                class="item__footer-btn"
                @click="showPreview(event)"
                v-if="
                  event.event.extendedProps.type !== 'story' &&
                  event.event.extendedProps.contentCaptionIds[0] !==
                    'blank-caption-id'
                "
              >
                <i class="icon-eye_preview"></i>
              </span>
              <span
                class="item__footer-btn"
                v-if="
                  !isDisabledItem(event.event.start) &&
                  event.event.extendedProps.type !== 'story'
                "
                @click="editPost(event)"
              >
                <i class="icon-content_creation"></i>
              </span>
              <span class="item__footer-btn" @click="deleteHandler(event)">
                <i class="icon-remove"></i>
              </span>
              <span
                v-if="
                  !isDisabledItem(event.event.start) &&
                  event.event.extendedProps.type !== 'story'
                "
                class="item__footer-btn right"
                @click="
                  goToEditPageHandler(event.event.extendedProps, event.event.id)
                "
              >
                <i class="icon-edit"></i>
              </span>
              <span
                v-else-if="!isDisabledItem(event.event.start)"
                class="item__footer-btn right"
                @click="
                  goToEditPageStoriesHandler(
                    event.event.extendedProps,
                    event.event.id
                  )
                "
              >
                <i class="icon-edit"></i>
              </span>
              <!--              <span-->
              <!--                class="item__header-btn"-->
              <!--                @click="-->
              <!--                  removeDialog.isShow = true;-->
              <!--                  deletePostId = event.event.id;-->
              <!--                "-->
              <!--              >-->
              <!--                <i class="icon-remove"></i>-->
              <!--              </span>-->
            </div>
          </div>
        </div>
      </template>
    </FullCalendar>
    <SlideIn
      :isShow="slideInIsShow"
      @close="closeSlideIn()"
      @reset="clickResetHandler"
    >
      <template v-slot:component_slot>
        <component
          :eventData="componentData"
          :slideInTitle="slideInTitle"
          :slideInType="slideInType"
          :topicList="topicList"
          :is="slideInComponent"
          :isClickReset="isClickReset"
          :isLoadingApproveBtn="isLoadingApproveBtn"
          @close="closeSlideIn()"
          @successResetForm="clickResetHandler"
          @updatePost="updatePost($event)"
          @updateEventData="updateComponentDataHandler"
          @saveBtnHandler="saveBtnHandler"
          @clickedApprove="clickedApproveHandler"
          @changeCaptionOnSlider="changeCaptionOnSliderHandler"
        ></component>
      </template>
    </SlideIn>
    <ModalDialog
      :isShow="removeDialog.isShow"
      :title="removeDialog.title"
      :icon="require('@/assets/img/icons/warning-icon.svg')"
      :footerButtons="removeDialog.footerButtons"
      @success="deletePost()"
      @close="
        removeDialog = { ...removeDialog, ...{ isShow: false } };
        deletePostId = null;
      "
    >
      <template v-slot:description>
        After removing it can not be recovered.
      </template>
    </ModalDialog>
    <ModalDialog
      :isShow="createDialog.isShow"
      :title="createDialog.title"
      :size="createDialog.isRush ? 500 : 415"
      :footerButtons="createDialog.footerButtons"
      @close="
        componentData = null;
        createDialog = {
          ...createDialog,
          ...{ isShow: false },
          ...{ isRush: false },
        };
      "
    >
      <template v-slot:description>
        <div class="create-buttons">
          <button @click="newPost()" class="btn violet">Create post</button>
          <button
            v-if="createDialog.isRush"
            @click="newRushPost()"
            class="btn violet"
          >
            Create rush post
          </button>
          <button @click="newStories()" class="btn violet">Create story</button>
        </div>
      </template>
    </ModalDialog>
    <ModalDialog
      :isShow="openTasksDialog.isShow"
      customClass="open_tasks"
      :size="openTasksDialog.size"
      :title="openTasksDialog.title"
      :footerButtons="openTasksDialog.footerButtons"
      @success="popupupTasksSuccess"
      @cancel="popupupTasksCancel"
      @close="popupupTasksCancel"
    >
      <template v-slot:description>
        Please note! You have {{ openTasksDialog.openTasksCount }} open Task
        items.
      </template>
    </ModalDialog>
    <Brief @hideBrief="hideBrief()" v-if="briefIsShow" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import "@fullcalendar/core/vdom";
import FullCalendar, { constrainPoint } from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import SlideIn from "@/components/global/SlideIn";
import CalendarPreview from "@/components/calendar/CalendarPreview";
import CalendarPrevImage from "@/components/calendar/CalendarPrevImage";
import CalendarPostForm from "@/components/calendar/CalendarPostForm";
import CalendarStoriesForm from "@/components/calendar/CalendarStoriesForm";
import ModalDialog from "@/components/global/ModalDialog";
import Loader from "@/components/global/Loader";
import helper from "@/mixins/helper";
import Brief from "@/components/brief/Index";
import H from "@/utils/helper";

import CalendarPost from "@/models/Post/CalendarPost";
import { log } from "logrocket";

export default {
  name: "Index",
  data() {
    return {
      isLoading: false,
      briefIsShow: false,
      isError: false,
      src: null,
      slideInIsShow: false,
      eventsList: [],
      topicList: [],
      monthData: null,
      slideInComponent: null,
      componentData: null,
      deletePostId: null,
      slideInTitle: "",
      slideInType: "",
      monthNumber: null,
      removeDialog: {
        isShow: false,
        title: "Are you sure to remove the item?",
        footerButtons: [
          { name: "Yes", emitName: "success", btnClass: "violet" },
          { name: "Cancel", emitName: "close" },
        ],
      },
      createDialog: {
        isShow: false,
        isRush: false,
        title: "Create new:",
      },
      isLoadingData: false,
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,dayGridWeek",
        },
        initialView: "dayGridMonth",
        editable: true,
        eventOrder: "type",
        selectable: false,
        selectMirror: true,
        events: this.eventsList,
        dayMaxEvents: true,
        weekends: true,
        firstDay: 1,
        height: "auto",
        eventDrop: this.dragItem,
        dateClick: this.createItem,
        datesSet: this.handleMonthChange,
      },
      calendar: null,
      isClickReset: false,
      openTasksDialog: {
        isShow: false,
        openTasksCount: 0,
        size: 700,
        title: "Customer Tasks",
        footerButtons: [
          { name: "Cancel", emitName: "cancel" },
          { name: "Show Tasks", emitName: "success", btnClass: "violet" },
        ],
      },
      currentCaptionObj: null,
      isLoadingApproveBtn: false,
    };
  },
  components: {
    FullCalendar,
    SlideIn,
    CalendarPostForm,
    CalendarStoriesForm,
    ModalDialog,
    CalendarPrevImage,
    Loader,
    Brief,
  },
  mixins: [helper],
  computed: {
    ...mapGetters("clientSelection", [
      "getCurrentCustomer",
      "getCustomerShowPopupTasks",
    ]),
    ...mapGetters("calendar", ["getEditedItemIdBeforeDelete"]),
    ...mapGetters("auth", ["getCurrentUser"]),
    calendarLocalStorageName() {
      return `calendarData_${this.getCurrentCustomer.id}`;
    },
  },
  created() {
    this.isLocalSizeCritical();
    if (this.getCurrentCustomer.show_brief) {
      this.briefIsShow = true;
    }
    if (this.getCustomerShowPopupTasks > 0) {
      this.openTasksDialog.openTasksCount = this.getCustomerShowPopupTasks;
      this.openTasksDialog.isShow = true;
    }
  },
  async mounted() {
    await this.fetchTopicList();
  },
  methods: {
    ...mapMutations("instagramPost", ["setIsStoriesTabActive"]),
    ...mapActions("clientSelection", ["updateCustomer"]),
    ...mapMutations("post", ["setCurrentPost"]),
    ...mapMutations("stories", ["setCurrentStories"]),
    ...mapMutations("calendar", ["setEditedItemIdBeforeDelete"]),
    ...mapActions("calendar", [
      "fetchCalendarData",
      "deleteCalendarPost",
      "updateDate",
      "getTopicList",
      "getPost",
      "getPostByCalendarID",
      "getStoryByCalendarID",
      "updatePostsDate",
      "savePost",
      "updatePostByAnyField",
    ]),
    ...mapActions("instagramPost", ["approveInstagramPost"]),
    ...mapMutations("clientSelection", ["setCustomerShowPopupTasks"]),
    ...mapActions("dashboard", ["updatePostCaption"]),

    async hideBrief() {
      this.briefIsShow = false;
      this.getCurrentCustomer.show_brief = false;
      try {
        await this.updateCustomer({
          customerId: this.getCurrentCustomer.id,
          params: {
            setShowBrief: true,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },

    isDisabledItem(date) {
      return this.$dayjs(date).diff(this.$dayjs(new Date()), "day") < 0;
    },

    popupupTasksSuccess() {
      this.setCustomerShowPopupTasks(0);
      this.openTasksDialog.isShow = false;
      this.$router.push({ name: "tasks" });
    },
    popupupTasksCancel() {
      this.setCustomerShowPopupTasks(0);
      this.openTasksDialog.isShow = false;
    },

    async handleMonthChange(e) {
      this.calendar = this.$refs.fullCalendar.getApi();
      await this.getCalendarEvents(e.start, e.end);
    },

    scrollToDate() {
      const dateElem = document.querySelector(
        `[data-date="${this.$dayjs(new Date()).format("YYYY-MM-DD")}"]`
      );
      setTimeout(() => {
        this.$scrollTo(dateElem, { offset: -50 });
      }, 100);
    },

    getLocalStoreValue() {
      return JSON.parse(localStorage.getItem(this.calendarLocalStorageName));
    },

    setLocalStorageVal(
      itemsList = this.getLocalStoreValue().calendarList || [],
      startDate = this.getLocalStoreValue().sinceVal || null,
      endDate = this.getLocalStoreValue().untilVal || null,
      lastUpdate = this.getLocalStoreValue().lastUpdate || null
    ) {
      localStorage.setItem(
        this.calendarLocalStorageName,
        JSON.stringify({
          calendarList: itemsList,
          sinceVal: startDate,
          untilVal: endDate,
          lastUpdate: lastUpdate,
        })
      );
    },

    async setCalendarDataHandler(params) {
      this.monthData = await this.fetchCalendarData(params);

      this.monthData = this.monthData.filter((value, index, self) => {
        return (
          self.indexOf(self.find((item) => item.postId === value.postId)) ===
          index
        );
      });
    },

    async clickPrevNextMonthHandler(params, ...paramsForLocalStorage) {
      await this.setCalendarDataHandler(params);

      const updatedResult = this.getLocalStoreValue().calendarList.filter(
        (e) => this.monthData.findIndex((i) => i.id === e.id) === -1
      );
      this.setLocalStorageVal(
        [...updatedResult, ...this.monthData],
        ...paramsForLocalStorage
      );
      this.setCalendarData(this.getLocalStoreValue().calendarList);
    },

    async getCalendarEvents(startDate, endDate, isGlobalSetData = false) {
      try {
        this.isLoading = true;
        const params = {
          customer: this.getCurrentCustomer.id,
          since: this.$dayjs(startDate).valueOf(),
          until: this.$dayjs(endDate).valueOf(),
        };

        if (!this.getLocalStoreValue()) {
          await this.setCalendarDataHandler(params);
          this.setLocalStorageVal(
            this.monthData,
            params.since,
            params.until,
            this.$dayjs(new Date()).valueOf()
          );
          this.setCalendarData(this.monthData);
        } else if (this.getLocalStoreValue().sinceVal > params.since) {
          await this.clickPrevNextMonthHandler(
            params,
            params.since,
            this.getLocalStoreValue().untilVal,
            this.$dayjs(new Date()).valueOf()
          );
        } else if (this.getLocalStoreValue().untilVal < params.until) {
          await this.clickPrevNextMonthHandler(
            params,
            this.getLocalStoreValue().sinceVal,
            params.until,
            this.$dayjs(new Date()).valueOf()
          );
        } else {
          this.isLoading = false;

          params.since = this.getLocalStoreValue().sinceVal;
          params.until = this.getLocalStoreValue().untilVal;
          params.lastUpdate = this.getLocalStoreValue().lastUpdate;

          await this.updateToLastVersionFromServerAndLocalStore(
            params,
            isGlobalSetData
          );
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },

    async updateToLastVersionFromServerAndLocalStore(
      params,
      isGlobalSetData = false
    ) {
      const updatedItems = await this.fetchCalendarData(params);

      if (!updatedItems.length) {
        this.setCalendarData(
          this.getLocalStoreValue().calendarList,
          isGlobalSetData
        );
      } else {
        this.updateCalendarData(updatedItems);
      }
    },

    updateCalendarData(updatedItems) {
      if (updatedItems.length) {
        updatedItems.forEach((item) => {
          if (this.calendar.getEventById(item.id)) {
            this.calendar.getEventById(item.id).remove();
          }
        });

        this.updateLocalstorageCalendarData(updatedItems);
        this.addItemToCalendarList(this.getLocalStoreValue().calendarList);
      }
    },
    updateLocalstorageCalendarData(data) {
      const localStorageListWithoutUpdatedItems =
        this.getLocalStoreValue().calendarList.filter(
          (e) => data.findIndex((i) => i.id === e.id) === -1
        );

      this.setLocalStorageVal(
        [...localStorageListWithoutUpdatedItems, ...data],
        this.getLocalStoreValue().sinceVal,
        this.getLocalStoreValue().untilVal,
        this.$dayjs(new Date()).valueOf()
      );
    },

    setCalendarData(data, isGlobalSetData = true) {
      const uniqueData = data.filter((value, index, self) => {
        return (
          self.indexOf(self.find((item) => item.postId === value.postId)) ===
          index
        );
      });

      if (isGlobalSetData) this.calendar.removeAllEvents();
      uniqueData.forEach(async (item) => {
        if (item.postStatus === "done") {
          let data = await this.getPost(item.postId);
          if (data.length > 1) {
            let sortDate = data.sort(function (a, b) {
              return new Date(a.createAt) - new Date(b.createAt);
            });
            item.start =
              sortDate[data.length - 1].scheduledForStr.split("_")[0];
            if (sortDate[data.length - 1].customerApprove !== "deleted") {
              this.calendar.addEvent({
                ...item,
                post: sortDate[data.length - 1],
              });
            } else {
              this.deletedFromLocalStoreById(item.id);
            }
          } else {
            item.start = data[0].scheduledForStr.split("_")[0];
            if (data[0].customerApprove !== "deleted") {
              this.calendar.addEvent({
                ...item,
                post: data[0],
              });
            } else {
              this.deletedFromLocalStoreById(item.id);
            }
          }
        } else {
          this.calendar.addEvent(item);
        }
      });
      this.isLoading = false;
      this.scrollToDate();
    },

    updatePostList(date) {
      if (this.$dayjs(date.split("_")[0]).format("M") >= this.monthNumber) {
        this.getCalendarEvents(this.monthNumber);
      }
    },

    deletedFromLocalStoreById(id) {
      let filteredLocalStore = this.getLocalStoreValue().calendarList.filter(
        (item) => item.id !== id
      );
      this.setLocalStorageVal(filteredLocalStore);
    },

    updatePost(event) {
      let valueInLocalStore = this.getLocalStoreValue();

      const uniqueData = event.data.filter((value, index, self) => {
        return (
          self.indexOf(self.find((item) => item.postId === value.postId)) ===
          index
        );
      });

      if (
        (event.data.length && event.type === "create") ||
        (event.data.length && event.type === "createRush")
      ) {
        this.setCalendarData(uniqueData, false);
        this.setLocalStorageVal(
          [...this.getLocalStoreValue().calendarList, ...uniqueData],
          this.getLocalStoreValue().sinceVal,
          this.getLocalStoreValue().untilVal,
          this.$dayjs(new Date()).valueOf()
        );
      } else {
        let tempData = valueInLocalStore.calendarList.filter(
          (item) => item.id !== this.getEditedItemIdBeforeDelete
        );

        this.setLocalStorageVal(
          [...tempData, ...uniqueData],
          valueInLocalStore.sinceVal,
          valueInLocalStore.untilVal,
          valueInLocalStore.lastUpdate
        );
        this.calendar.getEventById(this.getEditedItemIdBeforeDelete).remove();
        this.setEditedItemIdBeforeDelete(null);
        this.setCalendarData(uniqueData, false);
      }
    },

    addItemToCalendarList(data) {
      this.setCalendarData(
        [...this.getLocalStoreValue().calendarList, ...data],
        false
      );
    },

    createItem(data) {
      if (this.$dayjs(data.date).diff(this.$dayjs(new Date()), "day") >= 0) {
        if (this.isRushDate(data)) this.createDialog.isRush = true;
        this.createDialog = { ...this.createDialog, ...{ isShow: true } };
        this.componentData = null;
        this.componentData = data;
      }
    },

    isRushDate(selectDate) {
      return (
        this.$dayjs(selectDate.date).format("DD/MM/YYYY") ===
          this.$dayjs(new Date()).format("DD/MM/YYYY") ||
        this.$dayjs(new Date()).add(1, "day").format("DD/MM/YYYY") ===
          this.$dayjs(selectDate.date).format("DD/MM/YYYY")
      );
    },

    newPost() {
      this.createDialog = { ...this.createDialog, ...{ isShow: false } };
      this.slideInComponent = CalendarPostForm;
      this.slideInIsShow = true;
      this.slideInTitle = "Create post";
      this.slideInType = "create";
    },
    newRushPost() {
      this.createDialog = { ...this.createDialog, ...{ isShow: false } };
      this.slideInComponent = CalendarPostForm;
      this.slideInIsShow = true;
      this.slideInTitle = "Create rush post";
      this.slideInType = "createRush";
    },
    async newStories() {
      try {
        this.createDialog = { ...this.createDialog, ...{ isShow: false } };
        this.isLoading = true;
        const params = {
          publishAtStr: this.componentData.dateStr + "_09-00-00",
          customerId: this.getCurrentCustomer.id,
          type: "story",
        };
        const data = await this.savePost(params);
        if (data.length) {
          data.forEach((item) => {
            this.calendar.addEvent(item);
          });
        }
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        console.log(e);
      }
    },

    closeSlideIn() {
      this.slideInIsShow = false;
      this.slideInComponent = null;
      this.slideInTitle = "";
      this.slideInType = "";
    },
    clickResetHandler() {
      this.isClickReset = !this.isClickReset;
    },
    showPreview(e) {
      this.slideInIsShow = true;
      this.slideInComponent = CalendarPreview;
      this.componentData = e.event.extendedProps;
    },
    editPost(e) {
      this.slideInIsShow = true;
      this.slideInComponent = CalendarPostForm;
      this.componentData = e.event;
      this.slideInTitle = "Edit post";
    },
    async deletePost() {
      this.isLoading = true;
      this.calendar.getEvents().forEach((item, index) => {
        if (item._def.publicId === this.deletePostId) {
          this.calendar.getEvents()[index].remove();
        }
      });
      await this.deleteCalendarPost(this.deletePostId);
      this.isLoading = false;
    },
    async dragItem(e) {
      let newDate = e.event.startStr;
      this.calendar.getEvents().forEach((item, index) => {
        if (this.$dayjs(newDate).diff(this.$dayjs(new Date()), "day") < 0) {
          if (item._def.publicId === e.event._def.publicId) {
            this.calendar.getEvents()[index].setStart(e.oldEvent.startStr, {
              maintainDuration: false,
            });
            this.updatePostList(e.oldEvent.startStr);
          }
          this.isLoading = false;
          return;
        } else {
          if (item._def.publicId === e.event._def.publicId) {
            this.calendar
              .getEvents()
              [index].setExtendedProp("publishAt", newDate);
          }
        }
      });
      if (this.$dayjs(newDate).diff(this.$dayjs(new Date()), "day") < 0) {
        this.isLoading = false;
        return;
      }
      if (e.event._def.extendedProps.postStatus === "done") {
        const id = e.event._def.extendedProps.post.id;
        const postId = e.event._def.extendedProps.post.calendarPostId;
        const scheduledForStr = `${e.event.startStr}_${
          e.event._def.extendedProps.publishAtStr.split("_")[1]
        }`;
        const params = {
          scheduledForStr,
          id,
        };
        await this.updatePostsDate(params);
        this.updateItemsAfterDragAndDrop({
          scheduledForStr,
          postId: postId,
        });
      } else {
        const id = e.event._def.publicId;
        const publishAtStr = `${e.event.startStr}_${
          e.event._def.extendedProps.publishAtStr.split("_")[1]
        }`;
        const params = {
          publishAtStr,
          id,
        };
        await this.updateDate(params);
        this.updateItemsAfterDragAndDrop(params);
      }
    },
    async deleteHandler(event) {
      try {
        const id = event.event._def.publicId;
        const publishAtStr = "2030-01-01_00-00-00";
        const since = this.getLocalStoreValue().sinceVal;
        const until = this.getLocalStoreValue().untilVal;
        const params = {
          publishAtStr,
          id,
        };

        await this.updateDate(params);

        localStorage.removeItem(this.calendarLocalStorageName);

        await this.getCalendarEvents(since, until, true);
      } catch (e) {
        throw e;
      }
    },
    updateItemsAfterDragAndDrop(changedParams) {
      let resultOfSearchInLocalStore =
        this.getLocalStoreValue().calendarList.find((localStoreItem) => {
          if (changedParams && changedParams.id) {
            return localStoreItem.id === changedParams.id;
          } else {
            return localStoreItem.postId === changedParams.postId;
          }
        });

      if (changedParams && changedParams.scheduledForStr) {
        resultOfSearchInLocalStore.scheduledForStr =
          changedParams.scheduledForStr;
        resultOfSearchInLocalStore.start =
          resultOfSearchInLocalStore.scheduledForStr.split("_")[0];
      } else {
        resultOfSearchInLocalStore.publishAtStr = changedParams.publishAtStr;
        resultOfSearchInLocalStore.start =
          resultOfSearchInLocalStore.publishAtStr.split("_")[0];
      }

      this.deletedFromLocalStoreById(resultOfSearchInLocalStore.id);
      this.updateLocalstorageCalendarData([resultOfSearchInLocalStore]);
    },
    async goToEditPageHandler(event, id) {
      if (event.postStatus === "done") {
        this.setIsStoriesTabActive(false);
        this.setCurrentPost({
          ...event.post,
          preferred_site: event.preferred_site,
        });

        this.$router.push({
          name: "dashboard",
          params: { isEdit: true, isFromCalendar: true, isReadyPost: true },
        });
      } else {
        const post = await this.getPostByCalendarID(id);
        this.setIsStoriesTabActive(false);
        this.setCurrentPost(post);

        this.$router.push({
          name: "dashboard",
          params: { isEdit: true, isFromCalendar: true, isCalendarItem: true },
        });
      }
    },
    async goToEditPageStoriesHandler(event, id) {
      if (event.postStatus === "done") {
        this.setIsStoriesTabActive(1);
        this.setCurrentStories(event.post);

        this.$router.push({
          name: "dashboard",
          params: { isEdit: true, isFromCalendar: true, isCalendarItem: true },
        });
      } else {
        const stories = await this.getStoryByCalendarID(id);
        this.setIsStoriesTabActive(1);
        this.setCurrentStories({
          ...stories,
          calendarPostId: event.postId,
          calendarItemId: id,
        });

        this.$router.push({
          name: "dashboard",
          params: { isEdit: true, isFromCalendar: true, isCalendarItem: true },
        });
      }
    },
    async fetchTopicList() {
      try {
        this.topicList = await this.getTopicList(
          this.getCurrentCustomer.domain
        );
        this.topicList = this.topicList.sort((a, b) =>
          a.alias.toLowerCase() > b.alias.toLowerCase() ? 1 : -1
        );
      } catch (e) {
        console.log(e);
      }
    },
    isLocalSizeCritical() {
      const currentSize =
        1024 * 1024 * 5 -
        unescape(encodeURIComponent(JSON.stringify(localStorage))).length;

      if (currentSize < 1000) {
        for (let key in localStorage) {
          key.includes("calendarData_") ? localStorage.removeItem(key) : null;
        }
      }
    },
    updateComponentDataHandler(newEventData) {
      this.componentData = { ...this.componentData, ...newEventData };
    },
    async saveBtnHandler(newData) {
      try {
        await this.updatePostByAnyField({
          id: this.componentData.copyId,
          contentHashtags: this.componentData.contentHashtags,
        });
        this.getLocalStoreValue().calendarList.forEach((item, index) => {
          if (item.id === this.componentData.copyId) {
            item = { ...item, ...newData };

            this.setLocalStorageVal([
              ...[item],
              ...this.getLocalStoreValue().calendarList.filter(
                (i) => i.id !== this.componentData.copyId
              ),
            ]);

            this.setCalendarData(this.getLocalStoreValue().calendarList, true);
          }
        });
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    async clickedApproveHandler() {
      this.isLoadingApproveBtn = true;
      const post = await this.getPostByCalendarID(this.componentData.copyId);
      post.contentCaptionIds = this.componentData.contentCaptionIds;
      await this.approvePostHandler(post);
      const since = this.getLocalStoreValue().sinceVal;
      const until = this.getLocalStoreValue().untilVal;

      localStorage.removeItem(this.calendarLocalStorageName);

      await this.getCalendarEvents(since, until, true);
      this.isLoadingApproveBtn = false;
      this.closeSlideIn();
    },
    async approvePostHandler(post) {
      try {
        let params = {
          ...post,
        };

        params.customerId = this.getCurrentCustomer.id;
        params.customerApprove = "none";

        if (this.currentCaptionObj) {
          const { text, id: contentCaptionId } = this.currentCaptionObj;
          params = { ...params, ...{ text, contentCaptionId } };
        }

        if (params.customerComment && params.customerComment.length)
          params.customerComment = "";

        params.customerId = this.getCurrentCustomer.id;

        /* if (params.media.length) {
          let media = [];

          await Promise.all(
            params.media.map(async (i, index) => {
              if (i.template) {
                const newlyUploadedMedia = await this.uploadObjectMedia(i);
                i.mediaUrl = newlyUploadedMedia.mediaUrl;
                i.id = newlyUploadedMedia.mediaId;
              }

              let imageCoordinates = null;
              let ratio = null;

              if (i.type === "image") {
                // const { coordinates } =
                //   this.$refs[`cropper${index}`][0].getResult();
                // imageCoordinates = coordinates;
                // ratio = this.getCropFormat;
              }

              media[index] = {
                ratio: ratio,
                cropDetails: imageCoordinates,
                mediaUrl: i.mediaUrl,
                // postMediaUrl: postMediaUrls[index],
                template: i.template,
                mediaId: i.id ? i.id : this.getMediaIdFromUr(i.mediaUrl),
                isLicensed: i.template && i.template.isLicensed,
              };
            })
          );
          params = { ...params, media };
        }*/

        await this.approveInstagramPost(
          Object.assign(params, { smmId: this.getCurrentUser.id })
        );
      } catch (e) {
        throw e;
      }
    },
    changeCaptionOnSliderHandler(captionData) {
      this.currentCaptionObj = captionData;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@fullcalendar/common/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.wrapper {
  height: 100%;
  padding: 20px;
  position: relative;
}

.post-number {
  position: absolute;
  display: flex;
  align-items: center;
  top: 29px;
  right: 165px;
  &__wrapper {
    margin-right: 10px;
    .name {
      font-size: 12px;
      font-weight: 600;
    }
    .data {
      font-size: 12px;
      font-weight: 600;
      margin-left: 5px;
    }
  }
}

.calendar-client {
  display: inline-flex;
  align-items: center;
  height: 40px;
  border-radius: 5px;
  padding: 0 15px;
  position: absolute;
  left: 205px;
  top: 21px;
  z-index: 10;
  background: rgba(126, 86, 137, 0.1);
  &__img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__name {
    font-size: 12px;
    font-weight: 600;
  }
}

.fc {
  width: 100%;

  ::v-deep {
    .fc-daygrid-day.fc-day-today {
      background-color: rgba(254, 58, 67, 0.5) !important;
    }

    .fc-daygrid-body-balanced {
      .fc-daygrid-day-events {
        position: static;
        visibility: inherit !important;
      }
    }

    .fc-daygrid-day-frame {
      .fc-daygrid-day-bg {
        height: 40px;
        width: calc(100% - 5px);
        position: relative;
        background: #5e5e5e;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: auto;
        transform: scale(0);
        &:before {
          content: "+";
          position: absolute;
          font-size: 30px;
          color: #fff;
        }
      }
      &:hover {
        .fc-daygrid-day-bg {
          transform: scale(1);
        }
      }
    }
    .fc-daygrid-day.fc-day-past {
      .fc-daygrid-day-frame {
        &:hover {
          .fc-daygrid-day-bg {
            transform: scale(0);
          }
        }
      }
    }

    .fc-daygrid-event-harness-abs {
      position: static;
      visibility: inherit !important;
    }

    .fc-daygrid-day-bottom {
      display: none;
    }

    .fc-daygrid-day-frame {
      min-height: 200px;
      height: 100%;
    }

    .fc-event-main {
      overflow-y: auto;
    }

    .fc-daygrid-block-event {
      border: none;
      background: transparent;
    }

    .fc-daygrid-day-number,
    .fc-col-header-cell-cushion {
      color: $color-violet !important;
    }

    .fc-toolbar-chunk {
      .fc-button-group {
        .fc-button {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: $color-violet;
          border-color: #ccc;

          .fc-icon {
            display: inline-flex;
            align-items: center;
          }
        }
      }

      .fc-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: $color-violet;
        border-color: #ccc;
      }
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    border: 1px solid #eeeff0;
    box-sizing: border-box;
    box-shadow: 0 2px 12px rgb(31 35 37 / 6%) !important;
    background: #897cea !important;
    color: $color-black;
    border-radius: 5px;
    padding: 5px 10px;
    white-space: break-spaces;
    &.disabled {
      opacity: 0.4;
    }

    &.done {
      background: #f5c85f !important;
    }

    &.story {
      background: #f5c2c5 !important;
      &.done {
        background: #f5c85f !important;
      }
      .item {
        &__img {
          width: 50px;
          height: 90px;
          margin: 0 auto 5px auto;
          img,
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        &__header {
          display: inline-flex;
        }
      }
    }

    &__header {
      &-title {
        font-size: 14px;
        font-weight: 600;
        white-space: normal;
      }
    }

    &__footer {
      width: 100%;
      display: flex;
      align-items: center;
      padding-top: 5px;
      border-top: 1px solid $color-white;

      &-btn {
        margin-right: 10px;
        cursor: pointer;
        transition: all 0.3s ease;
        color: $color-violet;
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 3px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);

        i {
          font-size: 12px;
        }

        .icon-eye_preview {
          font-size: 16px;
        }

        &:hover {
          color: darken($color-violet, 10);
        }

        &.right {
          margin-left: auto;
          margin-right: 0;
        }
      }
    }

    &__title {
      white-space: initial;
      font-weight: 600;
      margin-bottom: 5px;
    }

    &__link {
      white-space: initial;
      font-weight: 600;
      margin-bottom: 5px;
      color: #195dd8;
      text-decoration: underline;
      word-break: break-all;
      font-size: 13px;
    }

    &__time {
      font-size: 12px;
      font-weight: 600;
      white-space: initial;
    }

    .img {
      margin-bottom: 5px;
      text-align: center;

      img {
        max-height: 90px;
      }
    }

    &__img {
      width: 100%;
      height: 90px;
      margin: 0 auto 5px auto;
      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.create-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: -28px;
  margin-top: 10px;
}
</style>
