<template>
  <div class="slide-in">
    <Transition name="slide-fade">
      <div class="slide-in__body" v-if="isShow">
        <div class="slide-in__header">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <button @click="$emit('reset')" v-bind="attrs" v-on="on">
                <i class="icon-reset"></i>
              </button>
            </template>
            <span>Reset a form</span>
          </v-tooltip>

          <button class="close" @click="$emit('close')">
            <i class="icon-close"></i>
          </button>
        </div>
        <div class="slide-in__wrapper">
          <slot name="component_slot"></slot>
        </div>
      </div>
    </Transition>
    <div class="slide-in__overlay" v-if="isShow" @click="$emit('close')"></div>
  </div>
</template>

<script>
export default {
  name: "SlideIn",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.slide-in {
  &__overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 10;
  }
  &__body {
    background: #fff;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 500px;
    overflow-y: auto;
    z-index: 100;
    padding: 0 20px 20px 20px;
  }
  &__header {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    padding-top: 10px;
    justify-content: flex-end;
    align-items: center;
    button {
      display: inline-flex;
    }
    .icon-reset {
      font-size: 18px;
    }
    .close {
      width: 18px;
      height: 18px;
      margin-left: 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
