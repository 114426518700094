<template>
  <div class="calendar-form">
    <Loader v-if="isLoading" />
    <div class="calendar-form__title" v-if="slideInTitle">
      {{ slideInTitle }}
    </div>
    <div class="calendar-form__list">
      <div class="labels">
        <span v-if="slideInType !== 'createRush'" class="labels__title"
          >Search
          {{
            getCurrentCustomer.domain === "real_estate" ? "/ Listing link" : ""
          }}
          <v-tooltip v-if="getCurrentCustomer.domain === 'real_estate'" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="red" size="small" dark v-bind="attrs" v-on="on">
                mdi-alert-circle-outline
              </v-icon>
            </template>
            <span
              >Please paste the full listing link.
              <br />

              For example: https://www.atproperties.com/11339586
            </span>
          </v-tooltip>
        </span>
        <span v-else class="labels__title"
          >{{
            getCurrentCustomer.domain === "real_estate" ? "Listing link" : ""
          }}
          <v-tooltip v-if="getCurrentCustomer.domain === 'real_estate'" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="red" size="small" dark v-bind="attrs" v-on="on">
                mdi-alert-circle-outline
              </v-icon>
            </template>
            <span
              >Please paste the full listing link.
              <br />

              For example: https://www.atproperties.com/11339586
            </span>
          </v-tooltip>
        </span>
        <div class="form-control">
          <v-autocomplete
            v-model="topicValue"
            item-text="alias"
            :items="
              typeof searchValue === 'string' &&
              searchValue.trim() !== '' &&
              searchValue.trim().length > 1
                ? listOfContentSubject
                : topicList
            "
            outlined
            :return-object="true"
            @update:search-input="startSearchHandler"
            @change="setSearchData"
            clearable
          >
          </v-autocomplete>
        </div>
      </div>
      <form v-if="slideInType !== 'createRush'">
        <div class="labels">
          <span class="labels__title">Theme</span>
          <div class="form-control">
            <v-autocomplete
              :items="themeList"
              v-model="form.theme"
              outlined
              :menu-props="{ maxHeight: '600' }"
              @change="getSubTheme($event)"
              clearable
            >
            </v-autocomplete>
          </div>
        </div>
        <div class="labels">
          <span class="labels__title">Sub-theme</span>
          <div class="form-control">
            <v-autocomplete
              item-text="contentSubtheme"
              :items="subThemeList"
              item-value="subId"
              :menu-props="{ maxHeight: '600' }"
              v-model="form.subTheme"
              :return-object="true"
              outlined
              clearable
            >
            </v-autocomplete>
          </div>
        </div>
        <div class="labels">
          <span class="labels__title">Scheduling</span>
          <div class="scheduling__wrapper">
            <Scheduling
              class="scheduling"
              :date="currentDate"
              :disabledTime="-24"
              @changeDate="changeDateHandler"
              @clearDate="changeDateHandler('')"
            />
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <button
                  class="reset-btn"
                  @click.prevent="setPostDate"
                  v-bind="attrs"
                  v-on="on"
                >
                  <i class="icon-reset"></i>
                </button>
              </template>
              <span>Reset a scheduling</span>
            </v-tooltip>
          </div>
        </div>
      </form>
      <div class="controls">
        <button
          @click="$emit('close')"
          class="btn violet cancel controls__item"
        >
          Cancel
        </button>
        <button
          @click="saveForm()"
          class="btn violet controls__item"
          :disabled="formIsDisabled"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Scheduling from "@/components/dashboard/widgets/creationWidget/components/Scheduling";
import Loader from "@/components/global/Loader";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "CalendarPostForm",
  components: {
    Scheduling,
    Loader,
  },
  data: () => ({
    currentDate: "",
    subThemeList: [],
    themeList: [],
    postId: null,
    topicValue: null,
    form: {
      theme: "",
      subTheme: null,
    },
    isLoading: false,
    searchValue: "",
    listOfContentSubject: [],
  }),
  props: {
    topicList: {
      type: Array,
      default: () => [],
    },
    eventData: {
      type: Object,
      default: () => {},
    },
    slideInTitle: {
      type: String,
      default: "",
    },
    slideInType: {
      type: String,
      default: "",
    },
    isClickReset: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isClickReset(newValue) {
      if (newValue) {
        this.form = {
          theme: "",
          subTheme: null,
        };
        this.topicValue = null;
        this.searchValue = "";
        this.setPostDate();

        this.$emit("successResetForm");
      }
    },
  },
  async created() {
    this.setPostDate();
    await this.fetchTopicList();
    if (this.slideInType !== "create" && this.slideInType !== "createRush") {
      this.setFormData();
    }
    const listOfSubject = this.topicList.map((topic) => {
      if (topic.contentSubject && topic.contentSubject !== "null") {
        let result = Object.assign({}, topic);
        result.alias = result.contentSubject;
        return result;
      }
    });
    this.listOfContentSubject = [...this.topicList, ...listOfSubject];

    if (
      this.slideInType !== "create" &&
      this.eventData.extendedProps &&
      this.eventData.extendedProps.preferred_site
    ) {
      this.startSearchHandler(this.eventData.extendedProps.preferred_site);
      this.topicValue = this.listOfContentSubject.find(
        (item) =>
          item && item.alias === this.eventData.extendedProps.preferred_site
      );
      this.form.isLink = true;
    }
  },
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    formIsDisabled() {
      return !this.form.subTheme || this.currentDate === "";
    },
  },
  methods: {
    ...mapActions("calendar", ["savePost", "updatePost"]),
    ...mapMutations("calendar", ["setEditedItemIdBeforeDelete"]),

    startSearchHandler(searchValue) {
      this.searchValue = searchValue;

      if (
        this.getCurrentCustomer.domain === "real_estate" &&
        typeof searchValue === "string" &&
        searchValue.length > 0
      ) {
        if (this.isLink(searchValue) && this.isLink(searchValue).length === 1) {
          let result = Object.assign(
            {},
            this.listOfContentSubject.find(
              (i) =>
                i &&
                [
                  "67972eb6-d0fd-11ec-bd7a-96719b41e4aa",
                  "f6f91c80-1591-11ed-b236-f20da23890fa",
                ].includes(i.id)
            )
          );

          result.alias = this.isLink(searchValue)[0];
          result.isLink = true;

          if (this.listOfContentSubject.some((i) => i && i.isLink)) {
            this.listOfContentSubject = this.listOfContentSubject.filter(
              (i) => i && !i.isLink
            );
          }

          this.listOfContentSubject.push(result);
        }
      }
    },
    isLink(text) {
      const expression =
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi;
      const regex = new RegExp(expression);

      return text.match(regex);
    },
    setSearchData(data) {
      if (data) {
        this.getSubTheme(data.contentTheme);
        this.form.theme = data.contentTheme;
        this.form.subTheme = data;
        this.form.isLink = data && data.isLink;
      }
    },
    setPostDate() {
      this.currentDate = `${this.$dayjs(this.eventData.date).format(
        "YYYY-MM-DD"
      )}_12-00-00`;
    },
    changeDateHandler(date) {
      this.currentDate = date;
    },
    async fetchTopicList() {
      this.themeList = this.topicList.reduce((previousValue, currentValue) => {
        if (previousValue.indexOf(currentValue.contentTheme) === -1) {
          previousValue.push(currentValue.contentTheme);
        }
        return previousValue;
      }, []);
      this.themeList.sort();
    },
    getSubTheme(event) {
      this.form.subTheme = "";
      this.subThemeList = this.topicList.filter(
        (item) => item.contentTheme === event
      );
      this.subThemeList.sort((a, b) =>
        a.contentSubtheme > b.contentSubtheme ? 1 : -1
      );

      if (
        this.topicValue &&
        this.topicValue.alias &&
        this.isLink(this.topicValue.alias)
      ) {
        if (event !== "Time to Sell" && event !== "Listing") {
          this.topicValue = null;
          this.form.isLink = false;
          this.searchValue = "";
        }
      }
    },
    async saveForm() {
      this.isLoading = true;
      const params = {
        publishAtStr: this.currentDate,
        customerId: this.getCurrentCustomer.id,
        topicSubId: [this.form.subTheme.subId],
        id: this.postId ? this.postId : undefined,
        mls_link:
          this.searchValue && this.form.isLink
            ? this.topicValue.alias
            : undefined,
      };
      if (this.slideInType === "create") {
        const postData = await this.savePost(params);
        if (postData.length)
          this.$emit("updatePost", { data: postData, type: this.slideInType });
      } else if (this.slideInType === "createRush") {
        const postData = await this.savePost({ ...params, rush: true });
        if (postData.length) {
          this.$emit("updatePost", { data: postData, type: this.slideInType });
        }
      } else {
        typeof this.form.subTheme === "string"
          ? (params.topicSubId = [this.form.subTheme])
          : (params.topicSubId = [this.form.subTheme.subId]);
        try {
          const postData = await this.updatePost(params);
          this.setEditedItemIdBeforeDelete(params.id);
          if (postData.length) {
            this.$emit("updatePost", {
              data: postData,
              type: this.slideInType,
              itemId: this.eventData.id,
            });
          }
        } catch (e) {
          console.log(e);
        }
      }
      this.$emit("close");
      this.isLoading = false;
    },
    setFormData() {
      this.form.theme = this.eventData.extendedProps.contentTheme;
      this.getSubTheme(this.form.theme);
      let subThemItem = this.subThemeList.filter(
        (item) =>
          item.contentSubtheme.trim() ===
          this.eventData.extendedProps.contentSubtheme.trim()
      );
      this.form.subTheme = subThemItem[0].subId;
      this.currentDate = this.eventData.extendedProps.publishAtStr;
      this.postId = this.eventData.id;
    },
  },
};
</script>

<style scoped lang="scss">
.calendar-form {
  width: 100%;
  &__title {
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    .labels {
      &__title {
        width: 100%;
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        color: #1f2325;
        margin-bottom: 4px;
      }
      .form-control {
        ::v-deep {
          .v-text-field--outlined > .v-input__control > .v-input__slot {
            min-height: 36px;
            max-height: 36px;
          }
          .v-text-field__details {
            display: none !important;
          }
          .v-text-field--enclosed .v-input__append-inner {
            margin-top: 7px;
          }
          .v-input__append-outer {
            margin-top: 10px;
          }
        }
      }
      .scheduling {
        width: 100%;
        &__wrapper {
          margin: 0 -5px;
          display: flex;
          align-items: center;
          .reset-btn {
            margin-left: 10px;
          }
        }
        ::v-deep {
          .mx-datepicker {
            width: calc(50% - 5px);
            margin: 0 5px;
          }
        }
      }
    }
  }
  .controls {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__item {
      min-width: 150px;
      &[disabled] {
        background: #ccc;
      }
    }
  }
}
</style>
