var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[(_vm.isLoading)?_c('Loader'):_vm._e(),_c('div',{staticClass:"calendar-client"},[_c('div',{staticClass:"calendar-client__img"},[(_vm.getCurrentCustomer.picUrl)?_c('img',{attrs:{"src":_vm.getCurrentCustomer.picUrl,"alt":""}}):_c('img',{attrs:{"src":require("@/assets/default-profile-picture.png"),"alt":""}})]),_c('div',{staticClass:"calendar-client__name"},[_vm._v(_vm._s(_vm.getCurrentCustomer.name))])]),_c('div',{staticClass:"post-number"},[_c('div',{staticClass:"post-number__wrapper"},[_c('span',{staticClass:"name"},[_vm._v("Weekly Posts:")]),(_vm.getCurrentCustomer.post_per_week)?_c('span',{staticClass:"data"},[_vm._v(_vm._s(_vm.getCurrentCustomer.post_per_week))]):_vm._e()]),_c('div',{staticClass:"post-number__wrapper"},[_c('span',{staticClass:"name"},[_vm._v("Weekly Stories:")]),(_vm.getCurrentCustomer.story_per_week)?_c('span',{staticClass:"data"},[_vm._v(_vm._s(_vm.getCurrentCustomer.story_per_week))]):_vm._e()])]),_c('FullCalendar',{ref:"fullCalendar",attrs:{"options":_vm.calendarOptions},scopedSlots:_vm._u([{key:"eventContent",fn:function(event){return [_c('div',{staticClass:"item",class:{
          done: event.event.extendedProps.postStatus === 'done',
          disabled: _vm.isDisabledItem(event.event.start),
          auto: event.event.extendedProps.generation === 'auto',
          story: event.event.extendedProps.type === 'story',
        }},[(event.event.extendedProps.postStatus === 'done')?_c('div',{staticClass:"item__wrapper"},[(event.event.extendedProps.type === 'story')?_c('div',{staticClass:"item__header"},[_c('div',{staticClass:"item__header-title"},[_vm._v("Story")])]):_vm._e(),_c('div',{staticClass:"item__time"},[_vm._v(" Publish time: "+_vm._s(event.event.extendedProps.post.scheduledForStr .split("_")[1] .substr(0, 5))+" ")]),(event.event.extendedProps.post.contentSubject)?_c('div',{staticClass:"item__title"},[_vm._v(" "+_vm._s(event.event.extendedProps.post.contentSubject)+" ")]):_vm._e(),(event.event.extendedProps.post.media.length)?_c('div',{staticClass:"item__img"},[(
                _vm.isImage(
                  event.event.extendedProps.post.media[0]
                    .editedMediaUrlReduced
                    ? event.event.extendedProps.post.media[0]
                        .editedMediaUrlReduced
                    : event.event.extendedProps.post.media[0].mediaUrl
                )
              )?_c('img',{attrs:{"src":event.event.extendedProps.post.media[0].editedMediaUrlReduced
                  ? event.event.extendedProps.post.media[0]
                      .editedMediaUrlReduced
                  : event.event.extendedProps.post.media[0].mediaUrl,"alt":""}}):_c('video',{attrs:{"controls":"","src":event.event.extendedProps.post.media[0].editedMediaUrl
                  ? event.event.extendedProps.post.media[0].editedMediaUrl
                  : event.event.extendedProps.post.media[0].mediaUrl}})]):_vm._e(),(event.event.extendedProps.preferred_site)?_c('a',{staticClass:"item__link",attrs:{"target":"_blank","href":event.event.extendedProps.preferred_site}},[_vm._v(_vm._s(event.event.extendedProps.preferred_site))]):_vm._e(),_c('div',{staticClass:"item__footer"},[_c('span',{staticClass:"item__footer-btn",on:{"click":function($event){return _vm.showPreview(event)}}},[_c('i',{staticClass:"icon-eye_preview"})]),(
                !_vm.isDisabledItem(event.event.start) &&
                event.event.extendedProps.type !== 'story'
              )?_c('span',{staticClass:"item__footer-btn right",on:{"click":function($event){return _vm.goToEditPageHandler(event.event.extendedProps, event.event.id)}}},[_c('i',{staticClass:"icon-edit"})]):(!_vm.isDisabledItem(event.event.start))?_c('span',{staticClass:"item__footer-btn right",on:{"click":function($event){return _vm.goToEditPageStoriesHandler(
                  event.event.extendedProps,
                  event.event.id
                )}}},[_c('i',{staticClass:"icon-edit"})]):_vm._e()])]):_c('div',{staticClass:"item__wrapper"},[(event.event.extendedProps.type === 'story')?_c('div',{staticClass:"item__header"},[_c('div',{staticClass:"item__header-title"},[_vm._v("Story")]),_vm._v(" "+_vm._s(event.event.extendedProps.post)+" ")]):_vm._e(),_c('div',{staticClass:"item__time"},[_vm._v(" Publish time: "+_vm._s(event.event.extendedProps.publishAtStr .split("_")[1] .substr(0, 5))+" ")]),(event.event.extendedProps.contentSubtheme)?_c('div',{staticClass:"item__title"},[_vm._v(" "+_vm._s(event.event.extendedProps.contentSubtheme)+" ")]):_vm._e(),_c('CalendarPrevImage',{staticClass:"img",attrs:{"imgId":event.event.extendedProps.contentMediaIds[0]}}),(event.event.extendedProps.preferred_site)?_c('a',{staticClass:"item__link",attrs:{"target":"_blank","href":event.event.extendedProps.preferred_site}},[_vm._v(_vm._s(event.event.extendedProps.preferred_site))]):_vm._e(),(!_vm.isDisabledItem(event.event.start))?_c('div',{staticClass:"item__footer"},[(
                event.event.extendedProps.type !== 'story' &&
                event.event.extendedProps.contentCaptionIds[0] !==
                  'blank-caption-id'
              )?_c('span',{staticClass:"item__footer-btn",on:{"click":function($event){return _vm.showPreview(event)}}},[_c('i',{staticClass:"icon-eye_preview"})]):_vm._e(),(
                !_vm.isDisabledItem(event.event.start) &&
                event.event.extendedProps.type !== 'story'
              )?_c('span',{staticClass:"item__footer-btn",on:{"click":function($event){return _vm.editPost(event)}}},[_c('i',{staticClass:"icon-content_creation"})]):_vm._e(),_c('span',{staticClass:"item__footer-btn",on:{"click":function($event){return _vm.deleteHandler(event)}}},[_c('i',{staticClass:"icon-remove"})]),(
                !_vm.isDisabledItem(event.event.start) &&
                event.event.extendedProps.type !== 'story'
              )?_c('span',{staticClass:"item__footer-btn right",on:{"click":function($event){return _vm.goToEditPageHandler(event.event.extendedProps, event.event.id)}}},[_c('i',{staticClass:"icon-edit"})]):(!_vm.isDisabledItem(event.event.start))?_c('span',{staticClass:"item__footer-btn right",on:{"click":function($event){return _vm.goToEditPageStoriesHandler(
                  event.event.extendedProps,
                  event.event.id
                )}}},[_c('i',{staticClass:"icon-edit"})]):_vm._e()]):_vm._e()],1)])]}}])}),_c('SlideIn',{attrs:{"isShow":_vm.slideInIsShow},on:{"close":function($event){return _vm.closeSlideIn()},"reset":_vm.clickResetHandler},scopedSlots:_vm._u([{key:"component_slot",fn:function(){return [_c(_vm.slideInComponent,{tag:"component",attrs:{"eventData":_vm.componentData,"slideInTitle":_vm.slideInTitle,"slideInType":_vm.slideInType,"topicList":_vm.topicList,"isClickReset":_vm.isClickReset,"isLoadingApproveBtn":_vm.isLoadingApproveBtn},on:{"close":function($event){return _vm.closeSlideIn()},"successResetForm":_vm.clickResetHandler,"updatePost":function($event){return _vm.updatePost($event)},"updateEventData":_vm.updateComponentDataHandler,"saveBtnHandler":_vm.saveBtnHandler,"clickedApprove":_vm.clickedApproveHandler,"changeCaptionOnSlider":_vm.changeCaptionOnSliderHandler}})]},proxy:true}])}),_c('ModalDialog',{attrs:{"isShow":_vm.removeDialog.isShow,"title":_vm.removeDialog.title,"icon":require('@/assets/img/icons/warning-icon.svg'),"footerButtons":_vm.removeDialog.footerButtons},on:{"success":function($event){return _vm.deletePost()},"close":function($event){_vm.removeDialog = { ..._vm.removeDialog, ...{ isShow: false } };
      _vm.deletePostId = null;}},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(" After removing it can not be recovered. ")]},proxy:true}])}),_c('ModalDialog',{attrs:{"isShow":_vm.createDialog.isShow,"title":_vm.createDialog.title,"size":_vm.createDialog.isRush ? 500 : 415,"footerButtons":_vm.createDialog.footerButtons},on:{"close":function($event){_vm.componentData = null;
      _vm.createDialog = {
        ..._vm.createDialog,
        ...{ isShow: false },
        ...{ isRush: false },
      };}},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('div',{staticClass:"create-buttons"},[_c('button',{staticClass:"btn violet",on:{"click":function($event){return _vm.newPost()}}},[_vm._v("Create post")]),(_vm.createDialog.isRush)?_c('button',{staticClass:"btn violet",on:{"click":function($event){return _vm.newRushPost()}}},[_vm._v(" Create rush post ")]):_vm._e(),_c('button',{staticClass:"btn violet",on:{"click":function($event){return _vm.newStories()}}},[_vm._v("Create story")])])]},proxy:true}])}),_c('ModalDialog',{attrs:{"isShow":_vm.openTasksDialog.isShow,"customClass":"open_tasks","size":_vm.openTasksDialog.size,"title":_vm.openTasksDialog.title,"footerButtons":_vm.openTasksDialog.footerButtons},on:{"success":_vm.popupupTasksSuccess,"cancel":_vm.popupupTasksCancel,"close":_vm.popupupTasksCancel},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(" Please note! You have "+_vm._s(_vm.openTasksDialog.openTasksCount)+" open Task items. ")]},proxy:true}])}),(_vm.briefIsShow)?_c('Brief',{on:{"hideBrief":function($event){return _vm.hideBrief()}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }